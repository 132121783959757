.testimonial-carousel {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 20px;
  width: 100%;
}

.testimonial-item {
  flex: 0 0 80%;
  margin: 30px 40px;
  padding: 25px;
  border: 2px solid #619b15;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(190, 38, 38, 0.1);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  text-align: center;
  background-color: #F8F9FA;
  opacity: 0;
  transform: translateX(50px);
}

.testimonial-item.active {
  transform: scale(1.05) translateX(0); /* Scale and slide into position */
  opacity: 1; /* Make the item visible */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.testimonial-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.testimonial-item h4 {
  margin-top: 0;
}

.testimonial-item .d-flex {
  margin-bottom: 10px;
  justify-content: center;
}

.testimonial-item .fas {
  font-size: 18px;
  margin-right: 5px;
}

.testimonial-item p {
  font-size: 16px;
  margin-bottom: 10px;
}

.btn-prev, .btn-next {
  position: absolute;
  bottom: 20px; /* Position at the bottom */
  border: none;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  z-index: 1;
  background-color: transparent;
  color: #619b15;
}

.btn-prev {
  left: 10px; /* Adjust as necessary */
}

.btn-next {
  right: 10px; /* Adjust as necessary */
}

.fa-star {
  color: #e4c423;
}

.text2 {
  color: #FFB524;
  padding-top: 60px;
}

hr {
  margin: 10px 100px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .testimonial-carousel {
    padding: 10px;
  }

  .testimonial-item {
    flex: 0 0 100%;
    margin: 20px 10px;
    padding: 20px;
  }

  .btn-prev, .btn-next {
    padding: 5px;
    font-size: 16px;
  }

  .text2 {
    padding-top: 30px;
  }

  hr {
    margin: 10px 20px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .testimonial-carousel {
    padding: 15px;
  }

  .testimonial-item {
    flex: 0 0 90%;
    margin: 25px 20px;
    padding: 22px;
  }

  .btn-prev, .btn-next {
    padding: 7px;
    font-size: 17px;
  }

  .text2 {
    padding-top: 50px;
  }

  hr {
    margin: 10px 50px;
  }
}

@media (min-width: 1025px) {
  .testimonial-carousel {
    padding: 20px;
  }

  .testimonial-item {
    flex: 0 0 80%;
    margin: 30px 40px;
    padding: 25px;
  }

  .btn-prev, .btn-next {
    padding: 10px;
    font-size: 18px;
  }

  .text2 {
    padding-top: 60px;
  }

  hr {
    margin: 10px 100px;
  }
}
