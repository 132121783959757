/* Product layout grid */
.product-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;
  height: 100vh;
  padding: 20px;
}

.product-image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-image img {
  width: 100%;
  max-height: 500px; /* Set a maximum height for large images */
  height: auto;      /* Maintain aspect ratio */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 70px;
  object-fit: contain; /* Ensures the entire image fits without cropping */
}


/* Product details section */
.product-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;
}

.product-details h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 200;
}

.product-price {
  font-size: 20px;
  font-weight: 100;
  color: #333;
  margin: 10px 0;
}

.product-price-size {
  font-size: 14px;
  color: #666;
  margin-left: 5px;
  vertical-align: middle;
}

.product-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.product-category {
  font-size: 1.2rem;
  font-style: italic;
  color: #666;
  margin-bottom: 10px;
}

.product-description {
  font-size: 1rem;
  line-height: 1.6;
  color: #444;
  margin-bottom: 15px;
}

/* Buttons section with left and right alignment */
.product-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
}

.btn-add-to-cart,
.btn-go-to-cart {
  flex: 1;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-add-to-cart {
  background-color: #81C408;
  color: white;
}

.btn-add-to-cart:hover {
  background-color: #6EA306;
}

.btn-go-to-cart {
  background-color: #81C408;
  color: white;
}

.btn-go-to-cart:hover {
  background-color: #6EA306;
}

/* Responsive design for tablets and small screens */
@media (max-width: 768px) {
  .product-layout {
    grid-template-columns: 1fr;
    height: auto;
    gap: 15px;
  }

  .product-title {
    font-size: 1.8rem;
  }

  .product-price {
    font-size: 1.4rem;
  }

  .product-buttons {
    flex-direction: column;
  }

  .btn-add-to-cart,
  .btn-go-to-cart {
    width: 100%;
    padding: 12px;
    margin-bottom: 10px;
  }

  .product-description {
    font-size: 0.95rem;
  }
}

/* Responsive design for mobile devices < 480px */
@media (max-width: 480px) {
  .product-layout {
    grid-template-columns: 1fr;
    gap: 10px;
    height: auto;
    padding: 10px;
  }

  .product-image img {
    height: auto;
    margin-top: 80px;
  }

  .product-title {
    font-size: 1.5rem;
  }

  .product-category {
    font-size: 1rem;
  }

  .product-price {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .btn-add-to-cart,
  .btn-go-to-cart {
    width: 100%;
    padding: 10px;
    font-size: 0.9rem;
  }

  .product-description {
    font-size: 0.85rem;
  }
}
