/* General Styles */
.product-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
}

.product-card {
    background: #fff;
    border: 1px solid #FFB524;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s, box-shadow 0.2s;
    width: 250px;
    text-align: center;
    padding: 10px;
}

.product-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Heading Styles */
.heading, .h2 {
    font-size: 3em;
    margin-top: 50px;
    margin-bottom: 0px;
    font-family: 'Trebuchet MS', Arial, sans-serif;
    color: #FFB524;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    font-weight: bold;
}

/* Hover-Zoom Effect for Product Images */
.product-image {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
    transition: transform 0.3s ease-in-out, border 0.3s ease-in-out;
}

.product-card:hover .product-image {
    transform: scale(1.1);
    border-bottom: none;
}

/* Product Details */
.product-name {
    font-size: 1.2em;
    margin: 10px 0;
}

.product-cost {
    font-size: 1.1em;
    color: #888;
    margin-bottom: 20px;
}

/* Style for the Buy Now button */
.btn-buy-now {
    display: inline-block;
    padding: 12px 24px;
    background-color: #FFB524;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 15px;
}

.btn-buy-now:hover {
    background-color: #FF9E00;
    transform: translateY(-3px);
}

.btn-buy-now:active {
    background-color: #FF7F00;
    transform: translateY(0);
}

.btn-buy-now:focus {
    outline: none;
}

/* Product Filters */
.product-filters {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    padding: 20px 70px;
}

.product-filters button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    background-color: #81C408;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.product-filters button:hover {
    background-color: #FFB524;
}

/* Responsive Styles */

/* Mobile view adjustments */
@media (max-width: 768px) {
    .product-card {
        width: 100%;
    }
    
    .product-filters {
        flex-direction: column;
        align-items: center;
        padding: 10px 20px;
    }
    
    .product-filters button {
        margin: 5px 0;
        width: 100%;
    }
    
    .h2 {
        padding-left: 20px;
        padding-top: 20px;
        font-size: 1.5em;
        text-align: center;
    }
    
    .btn-buy-now {
        width: 100%;
        padding: 12px 20px;
    }
}

/* Tablet view adjustments */
@media (min-width: 769px) and (max-width: 1024px) {
    .product-card {
        width: calc(50% - 40px); /* Adjust for two cards per row */
    }
    
    .product-filters {
        padding: 20px 40px;
    }
    
    .h2 {
        padding-left: 40px;
        font-size: 1.8em;
    }
}

/* Desktop view adjustments */
@media (min-width: 1025px) {
    .product-card {
        width: calc(25% - 40px); /* Reduced width for desktop (4 cards per row) */
    }
}

/* Add hover effect for the "read more" span */
.product-detail span.read-more {
    color: #888;
    cursor: pointer;
    transition: color 0.3s ease;
}

.product-detail span.read-more:hover {
    color: #FFB524;
}
