/* Container styling */
.cartitems {
  width: 90%;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
  margin-top: 7%;
}

.cartitems-format-main {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
  font-weight: bold;
  padding: 10px 0;
  border-bottom: 2px solid #ddd;
}

.cartitems-format {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

/* Product image styling */
.carticon-product-icon {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
}

/* Quantity control */
.cartitems-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

/* Hide text for mobile views */
.hide {
  display: block;
}


/* Cart totals section */
.cartitems-down {
  margin: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.cartitems-total h1 {
  font-size: 20px;
  margin-bottom: 10px;
}

.cartitems-total-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.cartitems-total-item p {
  margin: 0;
}

.cartitems-total hr {
  margin: 10px 0;
  border: 0;
  border-top: 1px solid #ddd;
}

.cartitems-down button {
  width: 200px;
  padding: 10px;
  margin-top: 10px;
  background-color: #81C408;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.cartitems-down button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Checkout dialog box */
.checkout-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 90vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

.checkout-dialog-box {
  background-color: #fff;
  padding: 20px;
  width: 400px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.checkout-dialog-box h2 {
  margin-bottom: 10px;
  color: #81C408;
}

.checkout-dialog-box pre {
  margin-bottom: 5px;
  color: #010100;
  font-family: Arial, Helvetica, sans-serif;
  font-style: bold;
}

.checkout-dialog-box input,
.checkout-dialog-box textarea {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  border: 1px solid #81C408;
  border-radius: 5px;
}

.checkout-dialog-box button {
  width: 80px;
  padding: 10px;
  margin-left: 60px;

  
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.checkout-dialog-box .sucess-button {
  background-color: #82c408b3;
  color: white;
}

.checkout-dialog-box .sucess-button:hover {
  background-color: #81C408;
}

.checkout-dialog-box .danger-button {
  background-color: #ff4d4dc3;
  color: white;
}

.checkout-dialog-box .danger-button:hover {
  background-color: #fd0f0fd9;
}
/* Input container for floating label effect */
.input-container {
  position: relative;
  margin-bottom: 20px;
}

input, textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input:focus, textarea:focus {
  border-color: #f39c12;
  outline: none;
}

label {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 16px;
  color: #aaa;
  transition: all 0.2s ease-in-out;
}

.input-filled {
  top: -10px;
  font-size: 12px;
  color: #f39c12;
}

/* Error message styling */
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}


/* Responsive styling */

/* Below 480px */
@media screen and (max-width: 480px) {
  .cartitems-format-main,
  .cartitems-format {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr; /* Image, name/price, and quantity */
    gap: 10px;
    align-items: center; /* Vertically center the content */
  }

  .cartitems-format img {
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 5px;
  }

  .cartitems-details {
    display: flex;
    justify-content: space-between; /* Space between name and price */
    gap: 10px;
  }

  .cartitems-details .item-name,
  .cartitems-details .item-price {
    text-align: left; /* Align the name and price to the left */
  }

  .cartitems-format .item-quantity {
    text-align: center; /* Center the quantity */
  }

  .cartitems-down {
    padding: 10px;
  }

  .cartitems-total h1 {
    font-size: 16px;
    color: #81C408;
  }

  .cartitems-total-item p {
    font-size: 14px;
  }

  .checkout-dialog-box {
    width: 90%;
  }
}


/* 481px to 760px */
@media screen and (min-width: 481px) and (max-width: 760px) {
  .cartitems-format-main,
  .cartitems-format {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }

  .cartitems-format img {
    width: 50px;
    height: 50px;
    display: block;
    margin-bottom: 10px;
  }

  .cartitems-down {
    padding: 15px;
  }

  .cartitems-total h1 {
    font-size: 18px;
  }

  .cartitems-total-item p {
    font-size: 15px;
  }

  .checkout-dialog-box {
    width: 80%;
  }
}

/* 760px to 1080px */
@media screen and (min-width: 761px) and (max-width: 1200px) {
  .cartitems-format-main,
  .cartitems-format {
    grid-template-columns: 1fr 2fr 1fr 1fr;
  }

  .cartitems-format img {
    width: 60px;
    height: 60px;
  }

  .cartitems-quantity {
    gap: 15px;
  }

  .cartitems-down {
    padding: 20px;
  }

  .cartitems-total h1 {
    font-size: 20px;
  }

  .cartitems-total-item p {
    font-size: 16px;
  }

  .checkout-dialog-box {
    width: 70%;
  }
}

/* Above 1080px */
@media screen and (min-width: 1200px) {
  .cartitems-format-main,
  .cartitems-format {
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
  }

  .cartitems-format img {
    width: 60px;
    height: 60px;
  }

  .cartitems-quantity {
    gap: 15px;
  }

  .cartitems-down {
    padding: 20px;
  }

  .cartitems-total h1 {
    font-size: 20px;
  }

  .cartitems-total-item p {
    font-size: 16px;
  }

  .checkout-dialog-box {
    width: 400px;
  }
}
